// 2024 image
import set from "../assets/images/event image/2024/2-3 set/sat (1).webp";
import set1 from "../assets/images/event image/2024/2-3 set/sat (2).webp";
import set2 from "../assets/images/event image/2024/2-3 set/sat (3).webp";
import set3 from "../assets/images/event image/2024/2-3 set/sat (4).webp";
import set4 from "../assets/images/event image/2024/2-3 set/sat (5).webp";
import set5 from "../assets/images/event image/2024/2-3 set/sat (6).webp";
import set6 from "../assets/images/event image/2024/2-3 set/sat (7).webp";
import set7 from "../assets/images/event image/2024/2-3 set/sat (8).webp";
import set8 from "../assets/images/event image/2024/2-3 set/sat (9).webp";
import set9 from "../assets/images/event image/2024/2-3 set/sat (10).webp";
// february
import feb from "../assets/images/event image/2024/8-9 feb/feb (1).webp";
import feb1 from "../assets/images/event image/2024/8-9 feb/feb (2).webp";
import feb2 from "../assets/images/event image/2024/8-9 feb/feb (3).webp";
import feb3 from "../assets/images/event image/2024/8-9 feb/feb (4).webp";
import feb4 from "../assets/images/event image/2024/8-9 feb/feb (5).webp";
import feb5 from "../assets/images/event image/2024/8-9 feb/feb (6).webp";
import feb6 from "../assets/images/event image/2024/8-9 feb/feb (7).webp";
import feb7 from "../assets/images/event image/2024/8-9 feb/feb (8).webp";
import feb8 from "../assets/images/event image/2024/8-9 feb/feb (9).webp";
// aust
import aug from "../assets/images/event image/2024/29-30 aug/aug (1).webp";
import aug1 from "../assets/images/event image/2024/29-30 aug/aug (2).webp";
import aug2 from "../assets/images/event image/2024/29-30 aug/aug (3).webp";
import aug3 from "../assets/images/event image/2024/29-30 aug/aug (4).webp";
export const accordionData = [
  //2025
  {
    id: 1,
    title: "Events 2025",
    details: "ICSCE, PMFAI, 2024 DUBAI",
    subsections: [
      {
        mt: "0px",
        subId: "8-9 february",

        role: " The influence of optics in Lighting",
        subTitle: "ICSCE, PMFAI, DUBAI",
        images: [feb, feb1, feb2, feb3, feb4, feb5, feb6, feb7, feb8],
      },
    ],
  },
  {
    id: 1,
    title: "Events 2024",
    details: "ICSCE, PMFAI, 2024 DUBAI",
    subsections: [
      {
        subId: "29-30 August",
        role: "Talk by Solaange & Corrective Actions(In collaboration with IGBC)",
        subTitle: "IIAB, Hyderabad ",
        images: [aug, aug1, aug2, aug3],
      },
      {
        subId: "2-3 September",
        role: "Talk by Solaange & Corrective Actions(In collaboration with IGBC)",
        subTitle: " ICSCE, PMFAI 2024 DELHI ",
        images: [set, set1, set2, set3, set4, set5, set6, set7, set8, set9],
      },
    ],
  },
];
