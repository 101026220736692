// Import all the images
import img26 from "../assets/images/galleryEvents/a (1).jpg";
import img27 from "../assets/images/galleryEvents/a (2).jpg";
import img28 from "../assets/images/galleryEvents/a (3).jpg";
import img29 from "../assets/images/galleryEvents/a (4).jpg";
import img30 from "../assets/images/galleryEvents/a (5).jpg";
import img31 from "../assets/images/galleryEvents/a (6).jpg";
import img32 from "../assets/images/galleryEvents/a (7).jpg";
import img33 from "../assets/images/galleryEvents/a (8).jpg";
import img34 from "../assets/images/galleryEvents/a (9).jpg";
import img35 from "../assets/images/galleryEvents/a (10).jpg";
import img36 from "../assets/images/galleryEvents/a (11).jpg";
import img37 from "../assets/images/galleryEvents/a (12).jpg";
import img38 from "../assets/images/galleryEvents/a (13).jpg";
import img39 from "../assets/images/galleryEvents/a (14).jpg";
import img40 from "../assets/images/galleryEvents/a (15).jpg";
import img41 from "../assets/images/galleryEvents/a (16).jpg";
import img42 from "../assets/images/galleryEvents/a (17).jpg";
import img43 from "../assets/images/galleryEvents/a (18).jpg";
import img44 from "../assets/images/galleryEvents/a (19).jpg";
import img45 from "../assets/images/galleryEvents/a (20).jpg";
import img46 from "../assets/images/galleryEvents/a (21).jpg";


// Store the images in an array
const imageArray = [
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
];

export default imageArray;
