import about1 from "../assets/images/vector1.png";
import about2 from "../assets/images/vector2.png";
import about3 from "../assets/images/vector3.png";
import about4 from "../assets/images/vector4.png";
import about5 from "../assets/images/vector5.png";
import arpit from "../assets/images/Teams/arpit.jpg";
import delip from "../assets/images/Teams/delip.jpg";
import akshay from "../assets/images/Teams/akshay.jpg";

//dibe
import leaf from "../assets/images/Categories/leafR.png";
import indrutial from "../assets/images/Categories/indrutial.png";
import paint from "../assets/images/Categories/paint.png";
import silocon from "../assets/images/Categories/silocon.png";

import Manufacturing_Products from "../assets/images/FinalBanner/Manufacturing_Products.jpg";
import Trading_Products from "../assets/images/FinalBanner/Trading_Products.jpg";

import general from "../assets/images/dummy/general_emulsifiers.jpg";
import fertilizer from "../assets/images/home/FertilezerMain.jpg";

//
import Agro_Emulsifiers from "../assets/Product/agroEmulsifire.jpg";
import Botanical_Products from "../assets/images/Categories/newimg/Botanical_Products.jpg";
import Essential_Oil from "../assets/images/Categories/newimg/Essential_Oil.jpg";
import Orthosilicic_Acid from "../assets/Product/OrthoSilicic.jpg";
import Others from "../assets/images/Categories/newimg/Others.jpg";
import Pheromones from "../assets/images/Categories/newimg/Pheromones.jpg";
import Plant_Growth_Promoters from "../assets/images/Categories/newimg/Plant_Growth_Promoters.jpg";
import silicone_Based_Products from "../assets/images/Categories/newimg/silicone_Based_Products.jpg";

import Adjuvant_for_sl from "../assets/images/AgroEm/Adjuvant_For_SL.jpg";
import Conventional_Emulsifier from "../assets/images/AgroEm/Conventional_Emulsifier.jpg";
import Emulsifier_for_EC from "../assets/images/AgroEm/Emulsifier_for_EC.jpg";
import Nitrobenzene_Emulsifier from "../assets/images/AgroEm/Nitrobenzene_Emulsifier.jpg";
import Oil_Emulsifier from "../assets/images/AgroEm/Oil_Emulsifier.jpg";
import Surfactant_For_SC from "../assets/images/AgroEm/Surfactant_For_SC.jpg";
import Surfactant_For_WP from "../assets/images/AgroEm/Surfactant_For_WP.jpg";

// new webp images
// 1. adjuvant for sl
import adjaventForSL from "../assets/images/FentonImages webp/AdjuvantForSL/AdjuvantForSL.webp";
import conventionalEmulsifier from "../assets/images/FentonImages webp/AdjuvantForSL/ConventionalEmulsifier169.webp";
// argo chemical emulsifier

import Essential_Oil169 from "../assets/images/FentonImages webp/EssentialOil/Essential_Oil169.webp";

import Agro_Chemicals_Emulsifiers169 from "../assets/images/FentonImages webp/Agro_Chemicals_Emulsifiers/Agro_Chemicals_Emulsifiers169.webp";

// Botanical_Products169
import Botanical_Products169 from "../assets/images/FentonImages webp/Botanical Products/Botanical_Products169.webp";

// Emulsifier_for_EC11
import EmulsifierEc from "../assets/images/FentonImages webp/Emulsifier for EC/Emulsifier_for_EC11.webp";
import EmulsifierEc1 from "../assets/images/FentonImages webp/Emulsifier for EC/EmulsifierforEC169.webp";
// EssentialOil
import General_Emulsifiers from "../assets/images/FentonImages webp/General Emulsifiers/General_Emulsifiers.webp";

// Home&IndustrialCleaning169
import HomeIndustrialCleaning169 from "../assets/images/FentonImages webp/Home & Industrial Cleaning/Home&IndustrialCleaning169.webp";
// Manufacturing_Products169
import Manufacturing_Products169 from "../assets/images/FentonImages webp/Manufacturing Products/Manufacturing_Products169.webp";

// NitrobenzeneEmulsifier11
import NitrobenzeneEmulsifier11 from "../assets/images/FentonImages webp/Nitrobenzene Emulsifier/NitrobenzeneEmulsifier11.webp";
import NitrobenzeneEmulsifier169 from "../assets/images/FentonImages webp/Nitrobenzene Emulsifier/NitrobenzeneEmulsifier169.webp";

// Oil_Emulsifier11
import Oil_Emulsifier11 from "../assets/images/FentonImages webp/OilEmulsifier/Oil_Emulsifier11.webp";
import Oil_Emulsifier169 from "../assets/images/FentonImages webp/OilEmulsifier/Oil_Emulsifier169.webp";

// Others169
import Others169 from "../assets/images/FentonImages webp/Others/Others169.webp";
// Paint_Additives169
import Paint_Additives169 from "../assets/images/FentonImages webp/Paint Additives/Paint_Additives169.webp";

import fertilizewe11 from "../assets/images/FentonImages webp/Fertilizers/Fertilizers11.webp";
import fertilizewe169 from "../assets/images/FentonImages webp/Fertilizers/Fertilizers169.webp";
// Plant_Growth_Promoters169

// SurfactantForSC11
import SurfactantForSC11 from "../assets/images/FentonImages webp/Surfactant For SC/SurfactantForSC11.webp";
import SurfactantForSC11_new from "../assets/images/FentonImages webp/Surfactant For SC/SurfactantForSC169.webp";
// SurfactantForWP11
import SurfactantForWP11 from "../assets/images/FentonImages webp/Surfactant For WP/SurfactantForWP11.webp";
import SurfactantForWP169 from "../assets/images/FentonImages webp/Surfactant For WP/SurfactantForWP169.webp";
// Trading_Products169
import Trading_Products169 from "../assets/images/FentonImages webp/Trading Products/Trading_Products169.webp";

import Trading_Products11 from "../assets/images/FentonImages webp/Adjuvant_for_SL11.jpg";
import Plant_Growth_Promoters169 from "../assets/images/FentonImages webp/Plant Growth Promoters/Plant_Growth_Promoters169.webp";
import Paint_Coatings11 from "../assets/images/FentonImages webp/Paint_Coatings.jpg";
import General_Manufacturing11 from "../assets/images/FentonImages webp/General_Manufacturing11.jpg";
import Botanical_Products11 from "../assets/images/FentonImages webp/Botanical_Products11.jpg";
import Agro_Emulsifiers11 from "../assets/images/FentonImages webp/Agro_Emulsifiers11.jpg";
import Essential_Oils11 from "../assets/images/FentonImages webp/Essential_Oils11.jpg";
import Adjuvant_for_SL11 from "../assets/images/FentonImages webp/Adjuvant_for_SL11.jpg";
import Conventional_Emulsifier11 from "../assets/images/FentonImages webp/Conventional_Emulsifier11.jpg";
import Silicone_Super_Spreads11 from "../assets/images/FentonImages webp/Silicone_Super_Spreads11.jpg";
import Plant_Growth_Promoters11 from "../assets/images/FentonImages webp/Plant_Growth_Promoters11.jpg";
import Other_Category11 from "../assets/images/FentonImages webp/Other_Category11.jpg";
import Manufacturing_Products11 from "../assets/images/FentonImages webp/Manufacturing_Products11.jpg";

export const navLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Categories",
    path: "/Categories",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Contact",
    path: "/contact",
  },
  {
    title: "Career",
    path: "/career",
  },
];
//done
export const serviceLists = [
  {
    category: "Agro chemicals & Emulsifiers",
    image: leaf,
    big197: Agro_Chemicals_Emulsifiers169,
    small11: leaf,
    link: "/Agro",
    productDetails:
      "Agro surfactants are advanced additives designed to improve the performance of agricultural formulations, including pesticides, herbicides, and fertilizers. By reducing surface tension, these surfactants ensure better spreadability, penetration, and adhesion of active ingredients, enhancing their efficacy. They play a crucial role in modern farming, promoting sustainable practices by minimizing chemical runoff and ensuring targeted delivery to crops. Formulated to meet global environmental standards, agro surfactants support high-yield farming while safeguarding ecosystems.",
    subcategories: [
      {
        category: "Agro - Emulsifiers",
        subcategory: "Agro - Emulsifiers",
        image: Agro_Emulsifiers,
        big197: Agro_Chemicals_Emulsifiers169,
        small11: Agro_Emulsifiers11,
        link: "/Agro",
        productDetails:
          "Agro surfactants are advanced additives designed to improve the performance of agricultural formulations, including pesticides, herbicides, and fertilizers. By reducing surface tension, these surfactants ensure better spreadability, penetration, and adhesion of active ingredients, enhancing their efficacy. They play a crucial role in modern farming, promoting sustainable practices by minimizing chemical runoff and ensuring targeted delivery to crops. Formulated to meet global environmental standards, agro surfactants support high-yield farming while safeguarding ecosystems.",
        subcategories: [
          {
            subcategory: "Oil Emulsifier",
            image: Oil_Emulsifier,
            big197: Oil_Emulsifier169,
            small11: Oil_Emulsifier11,
            categoryWiseTable: 3,
            hotProduct: 3,
            tableForm: 0,
            howToUse:
              "Dilute the recommended amount of emulsifier in the solution and mix thoroughly for uniform distribution.",
            productDetails:
              "An oil emulsifier is a chemical agent that helps in mixing oil and water, forming a stable emulsion. It works by reducing the surface tension between two immiscible liquids, preventing separation and enhancing solubility. Oil emulsifiers are widely used in industries like textiles, paints, lubricants, agrochemicals, and cleaning formulations.",
            benefits: [
              {
                title: "Enhanced Stability and Performance",
                description: [
                  "Ensures long-lasting emulsion stability under various environmental conditions.",
                  "Prevents phase separation for consistent product application.",
                  "Extends the shelf life of agrochemical formulations by up to 30%.",
                ],
              },
              {
                title: "Improved Coverage and Efficiency",
                description: [
                  "Optimizes spray droplet distribution for better crop coverage.",
                  "Minimizes chemical runoff, reducing environmental contamination.",
                  "Enhances penetration in dense foliage and hard-to-reach areas.",
                ],
              },
              {
                title: "Eco-Friendly and Sustainable",
                description: [
                  "Composed of biodegradable and non-toxic ingredients.",
                  "Complies with global standards like EU REACH and FAO guidelines.",
                  "Supports reduced agrochemical usage, fostering sustainable agriculture.",
                ],
              },
            ],
            videoLinks: [
              "https://youtu.be/pkhIfimAgkc?si=c7XDhFdTKc6-3xuf",
              "https://youtu.be/FmJd4pMhCKo?si=qPg40IjGyWcaEhqu",
              "https://youtu.be/9DrwFYmNvW4?si=3zyonIQJ69-2EHg3",
              "https://youtu.be/vkCVvn02T9I?si=RCzw9toIrEQdoa9T",
              "https://youtu.be/ivz2Mv6ZtKc?si=XmU1hsbY6BIgOrJa",
              "https://youtu.be/ZkGN0Y_4DMc?si=Zhcx9nIMTST-wO2B",
              "https://youtu.be/52cCo6s8knM?si=yZ-ioqGu6ev_ouTq",
            ],
          },
          {
            subcategory: "Emulsifier for EC",
            image: Emulsifier_for_EC,
            big197: EmulsifierEc1,
            small11: EmulsifierEc,
            hotProduct: 0,
            categoryWiseTable: 1,
            tableForm: 1,
            howToUse:
              "Dilute the recommended amount of emulsifier in the mixture, stir well, and apply using standard spraying equipment for uniform coverage.",
            productDetails:
              "Emulsifiers are essential components in Emulsifiable Concentrates (EC) that help in the stable dispersion of active ingredients. They ensure proper mixing of oil-based formulations with water, enhancing product effectiveness and performance.",
            benefits: [
              {
                title: "Benefits of Our Emulsifiers",
                description: [
                  "Superior Stability – No phase separation or sedimentation.",
                  "Optimized Ratios – Perfect balance of anionic and non-ionic emulsifiers.",
                  "High Performance – Ensures efficient application and effectiveness.",
                  "Customizable Solutions – Tailored emulsifier blends based on formulation needs.",
                ],
              },
              {
                title: "Why Choose the Right Emulsifier?",
                description: [
                  "Selecting the right emulsifier is critical for:",
                  "Stable Formulation – Prevents phase separation and ensures longevity.",
                  "Improved Solubility – Enhances the dispersibility of active ingredients.",
                  "Effective Performance – Ensures consistent application and efficacy.",
                  "Enhanced Stability – Resistant to temperature variations and external conditions.",
                ],
              },
            ],
            videoLinks: [
              "https://youtu.be/hRq58W57FIA?si=cfmeLHFvxFV4AHbS",
              "https://youtu.be/x52pN67hGJE?si=hDOqVVSCyRuGB3s9",
              "https://youtu.be/soQMfPG2MOA?si=PWbAWpj97OWOhFoE",
            ],
          },
          {
            subcategory: "Nitrobenzene Emulsifier",
            image: Nitrobenzene_Emulsifier,
            big197: NitrobenzeneEmulsifier169,
            small11: NitrobenzeneEmulsifier11,
            hotProduct: 0,
            categoryWiseTable: 5,
            tableForm: 5,
            howToUse:
              "Mix the recommended dosage of nitrobenzene emulsifier with the agrochemical solution, agitate well, and spray directly onto crops for optimal results.",
            productDetails:
              "Nitrobenzene emulsifier is a specialized surfactant used to enhance the solubility and dispersion of nitrobenzene in water-based formulations. It is widely used in agriculture, particularly as a plant growth promoter, and in chemical industries for formulation stability.",
            benefits: [
              {
                title: "Features & Benefits",
                description: [
                  "Enhances the water solubility of nitrobenzene.",
                  "Provides excellent dispersion and stability.",
                  "Compatible with various agrochemical formulations.",
                  "Improves plant metabolism and growth stimulation.",
                  "Eco-friendly and biodegradable options available",
                ],
              },
              {
                title: "Applications of Nitrobenzene Emulsifier",
                description: [
                  "Agriculture: Used in plant growth promoters to enhance flowering and fruiting.",
                  "Agrochemicals: Helps in the formulation of pesticide and fertilizer blends.",
                  "Chemical Industry: Used in industrial emulsions and solvent-based formulations.",
                ],
              },
              {
                title: " How Nitrobenzene Emulsifier Works?",
                description: [
                  "Nitrobenzene itself is insoluble in water, but with the help of an emulsifier, it forms a stable dispersion, ensuring even application and maximum absorption in agricultural.",
                  "This leads to improved efficiency and performance in the intended use case.",
                ],
              },
            ],
            videoLinks: ["https://youtu.be/ROIV4y-crS8?si=LrgNllcX7qno7C8A"],
          },
          {
            subcategory: "Surfactant For SC",
            image: Surfactant_For_SC,
            big197: SurfactantForSC11_new,
            small11: SurfactantForSC11,
            hotProduct: 0,
            categoryWiseTable: 4,
            tableForm: 1,
            howToUse:
              "Mix the surfactant into the suspension concentrate (SC) formulation as per recommended dosage. Stir thoroughly to ensure proper dispersion and apply using conventional spraying methods.",
            productDetails:
              "Suspension Concentrates, also known as flowables, are highly effective liquid formulations used in agricultural applications. They consist of finely milled solid active ingredients dispersed in water, ensuring high stability and ease of use.",
            benefits: [
              {
                title: "Key Benefits of SC Formulations:",
                description: [
                  "No Dust: Reduces inhalation risks compared to powder formulations.",
                  "Non-Flammable & Non-Toxic: Safe to handle without solvent-related hazards.",
                  "Enhanced Efficiency: Smaller particle size ensures better coverage and performance.",
                  "Low Packaging Volume: Requires less storage space and is easy to transport.",
                  "Long-Term Stability: Prevents sedimentation, ensuring consistent performance.",
                  "Easily Pourable & Dispersible: Provides smooth application in agricultural settings.",
                ],
              },
              {
                title: " Role of Surfactants in SC Formulations:",
                description: [
                  "Surfactants are essential for stabilizing the formulation and ensuring proper dispersion. They aid in:",
                  "Wetting of solid particles.",
                  "Enhancing dispersibility in the liquid phase.",
                  "Preventing particle aggregation.",
                  "Ensuring stability in diluted applications.",
                ],
              },
              {
                title: "Composition of SC Formulation:",
                description: [
                  "A typical SC formulation includes:",
                  "  ACTIVE Ingridient : 50-800 g/L",
                  "Wetting Agents: 5-15 g/L",
                  "Dispersing Agents: 20-50 g/L",
                  "Antifreeze: 20-80 g/L (e.g., Monopropylene Glycol)",
                  "Antifoam & Stabilizers: 1-5 g/L",
                  "Thickener: 1-4 g/L (e.g., Xanthan Gum, Bentonite)",
                  "Preservative: Protects against bacterial contamination.",
                  "Water: Up to 1000 mL",
                ],
              },
              {
                KeyAdditives: [
                  "Wetting Agents: Improve solid particle wetting of hydrophobic actives",
                  "Dispersing Agents: Dispersing Agents: Dispersing are required to ensure the uniform dispersion of solid active particles after a formulations dilution & through out the formulation shelf life.",
                ],
              },
            ],
          },
          {
            subcategory: "Surfactant For WP",
            image: Surfactant_For_WP,
            big197: SurfactantForWP169,
            small11: SurfactantForWP11,
            hotProduct: 0,
            categoryWiseTable: 6,
            tableForm: 1,
            howToUse:
              "Add the recommended quantity of surfactant to the wettable powder (WP) mixture. Blend thoroughly and spray on crops using standard agricultural equipment.",
            productDetails:
              "Wettable Powders (WP) are dry, finely ground pesticide formulations that resemble dust but need to be mixed with water before application. They contain 5%–95% active ingredients, usually 50% or more, and do not dissolve in water but remain suspended when agitated.",
            benefits: [
              {
                title: "Key Features of Wettable Powders",
                description: [
                  "Highly Effective: WP formulations are widely used for pest control and work well with most spray equipment that provides constant agitation.",
                  "Excellent Residual Activity: The pesticide remains on surfaces like concrete, plaster, and untreated wood, ensuring long-lasting effects.",
                  "Flexible Application: Some products allow for both dust and WP applications, giving applicators more choices.",
                ],
              },
              {
                title: "Advantages of Wettable Powders",
                description: [
                  " Easy to store, transport, and handle.",
                  " Safer for plants and animals compared to ECs (Emulsifiable Concentrates).",
                  "Accurate dosing: Easily measured and mixed.",
                  "Lower skin and eye absorption risk than liquid pesticide formulations.",
                ],
              },
              {
                title: "Disadvantages of Wettable Powders",
                description: [
                  " Inhalation risk: Fine particles pose a hazard during mixing",
                  " Requires continuous agitation to prevent settling in the spray tank.",
                  "Abrasive to spray equipment: Can wear out pumps and nozzles quickly.",
                  "Difficult to mix in very hard or alkaline water.",
                  " May clog nozzles and leave visible residues on surfaces.",
                ],
              },
              {
                TypicalFormula: [
                  " Active Ingrident : 50-900 gms/kg",
                  "Surfactants : 30-80 gms/kg",
                  "Inert Filler : Quantity adjvsted to 1kg. ",
                ],
              },
            ],
          },
          {
            subcategory: "Adjuvant For SL",
            image: Adjuvant_for_sl,
            big197: adjaventForSL,
            small11: Adjuvant_for_SL11,
            hotProduct: 0,
            categoryWiseTable: 2,
            tableForm: 5,
            howToUse:
              "Dilute the adjuvant with the recommended volume of water and mix thoroughly before adding to the spray tank. Ensure proper agitation for consistent application.",
            productDetails:
              "Adjuvants for Soluble Liquid (SL) formulations play a crucial role in optimizing the performance of liquid-based agrochemicals. These specialized additives help improve solubility, enhance stability, and ensure better absorption of active ingredients, leading to more effective and efficient application.",
            benefits: [
              {
                title: "Key Benefits of Fenton’s SL Adjuvants",
                description: [
                  "Fenton’s SL adjuvants are designed to enhance the efficiency and stability of agrochemical applications. ",
                  " With superior solubility, bioavailability, and compatibility, they ensure optimal performance while reducing chemical usage.",
                  "Our scientifically proven formulations provide long-term stability, preventing separation and maintaining homogeneity for uniform application.",
                ],
              },
              {
                title: "Key Features & Advantages:",
                description: [
                  "Enhances Solubility & Dispersion - Ensures complete dissolution in water, preventing precipitation or clogging.",
                  "Boosts Bioavailability - Increases plant uptake and absorption, leading to better results with lower chemical usage.",
                  "Prevents Separation - Maintains homogeneity for uniform spray application.",
                  "Improves Storage Stability - Extends shelf life by preventing crystallization or sedimentation over time.",
                  "Compatible with Multiple Formulations - Works seamlessly with pesticides, herbicides, fungicides, and plant growth regulators.",
                  "Superior Performance - High-quality formulation ensures improved efficiency and application results.",
                  "Custom Solutions - Tailored adjuvant solutions to meet specific agricultural needs.",
                  "Cost-Effective - Reduces overall chemical usage while maximizing effectiveness.",
                  "Scientifically Proven Stability - Rigorously tested for compatibility, stability, and effectiveness in various conditions.",
                ],
              },
              {
                title: "Applications of SL Adjuvants",
                description: [
                  " Agriculture - Used in herbicides, insecticides, fungicides, and PGRs (Plant Growth Regulators) to improve efficiency and reduce wastage.",
                  "Enhances the solubility and absorption of nutrient-based liquid fertilizers.",
                  "-",
                  "Active ingredient : 5 - 50%",
                  "Adjuvant : 3 - 8%",
                  "Solvent : Only if Required",
                  "Water : Upto 100%",
                ],
              },
            ],
          },
          {
            subcategory: "Conventional Emulsifier",
            image: Conventional_Emulsifier,
            big197: conventionalEmulsifier,
            small11: Conventional_Emulsifier11,
            hotProduct: 0,
            tableForm: 5,
            categoryWiseTable: 11,
            howToUse:
              "Add the emulsifier to the spray solution as per recommended dosage. Stir thoroughly to ensure proper emulsification before application.",
            productDetails:
              "Conventional emulsifiers are surface-active agents that help stabilize emulsions by reducing interfacial tension between oil and water. These emulsifiers play a crucial role in various industrial applications, including agrochemicals, cosmetics, pharmaceuticals, and food production.",
            benefits: [
              {
                title: "Benefits of Using Fenton Chemical Emulsifiers",
                description: [
                  "High stability and efficiency in emulsification.",
                  "Compatible with a wide range of formulations.",
                  "Eco-friendly and biodegradable options available.",
                  "Cost-effective solutions with consistent quality.",
                ],
              },
              {
                title: "Types of Conventional Emulsifiers",
                description: [
                  "Non-Ionic Emulsifiers: Used for mild formulations, suitable for cosmetics and food.",
                  "Anionic Emulsifiers: Provide high stability, commonly used in detergents and agrochemicals.",
                  "Cationic Emulsifiers: Effective in acidic conditions, used in hair conditioners and asphalt emulsions.",
                  "Amphoteric Emulsifiers: Adaptable in different pH conditions, used in personal care products.",
                ],
              },
              {
                title: " Applications of Conventional Emulsifiers",
                description: [
                  "Agrochemicals: Enhancing pesticide and herbicide dispersion.",
                  "Pharmaceuticals: Used in creams, ointments, and drug formulations.",
                  "Cosmetics & Personal Care: Found in lotions, shampoos, and makeup.",
                  "Food Industry: Stabilizing dressings, sauces, and dairy products.",
                  "Paints & Coatings: Improving pigment dispersion and coating uniformity.",
                ],
              },
            ],
          },
        ],
      },
      {
        subcategory: "Silicone Super Spreader",
        image: silocon,
        // big197: conventionalEmulsifier,
        hotProduct: 3,
        small11: Silicone_Super_Spreads11,
        howToUse:
          "Dilute the Silicone Super Spreader as directed and apply evenly on the target area for maximum effectiveness.",
        productDetails:
          "Silicone Super Spreader is a cutting-edge surfactant designed to enhance the spreading and absorption of agricultural sprays, including pesticides, herbicides, and fungicides. This innovative product reduces surface tension, allowing for uniform coverage on plant surfaces, even on waxy or difficult-to-wet leaves. Its superior spreading properties improve the penetration of active ingredients, ensuring higher efficacy and reduced wastage. Silicone Super Spreader is highly effective at low concentrations, making it a cost-efficient solution for modern farming needs. Suitable for use in various agricultural and horticultural applications, it supports sustainable and productive practices.",
        benefits: [
          {
            title: "Enhanced Coverage",
            description: [
              "Reduces surface tension to provide even spreading on all plant surfaces.",
              "Ensures effective application on hard-to-wet areas like waxy or hairy leaves.",
              "Improves penetration of active ingredients for better efficacy.",
              "Minimizes spray drift, ensuring targeted application.",
            ],
          },
          {
            title: "Cost Efficiency",
            description: [
              "Reduces the volume of spray solution required, saving resources.",
              "Highly effective at low concentrations, lowering overall product usage.",
              "Minimizes wastage by maximizing the efficiency of active ingredients.",
              "Offers long-term savings by improving yield and reducing reapplication needs.",
            ],
          },
          {
            title: "Eco-Friendly Solution",
            description: [
              "Decreases chemical runoff, reducing environmental impact.",
              "Supports sustainable farming by optimizing resource utilization.",
              "Safe for crops, soil, and surrounding ecosystems when used as directed.",
              "Complies with environmental and agricultural safety standards.",
            ],
          },
        ],
        videoLinks: [
          "https://youtu.be/GFtfutzCE-o?si=v9KfLGZKm5IP1v4R",
          "https://youtu.be/tMEJ60ZTOGA?si=iGZ962xdf5YgXJgU",
          "https://youtu.be/mpW5EGLfs7w?si=VzoOTbMBHMkiXCLP",
        ],
      },
      {
        subcategory: "Silicone Based Products",
        image: silicone_Based_Products,
        categoryWiseTable: 8,
        hotProduct: 0,
        tableForm: 0,
        howToUse:
          "Dilute the recommended amount of emulsifier in the solution and mix thoroughly for uniform distribution.",
        productDetails:
          "Silicon-based products play a crucial role in modern agriculture by improving plant health, enhancing nutrient uptake, and providing protection against pests and diseases. With increasing focus on sustainable farming, these products are gaining popularity for their multifunctional benefits. ",
        benefits: [
          {
            title: "Enhanced Stability and Performance",
            description: [
              "Ensures long-lasting emulsion stability under various environmental conditions.",
              "Prevents phase separation for consistent product application.",
              "Extends the shelf life of agrochemical formulations by up to 30%.",
            ],
          },
          {
            title: "Improved Coverage and Efficiency",
            description: [
              "Optimizes spray droplet distribution for better crop coverage.",
              "Minimizes chemical runoff, reducing environmental contamination.",
              "Enhances penetration in dense foliage and hard-to-reach areas.",
            ],
          },
          {
            title: "Eco-Friendly and Sustainable",
            description: [
              "Composed of biodegradable and non-toxic ingredients.",
              "Complies with global standards like EU REACH and FAO guidelines.",
              "Supports reduced agrochemical usage, fostering sustainable agriculture.",
            ],
          },
        ],
      },
      {
        subcategory: "Orthosilicic Acid",
        image: Orthosilicic_Acid,
        tableForm: 0,
        link: "/Orthosilicic_Acid",
      },
      {
        subcategory: "Botanical Products",
        image: Botanical_Products,
        big197: Botanical_Products169,
        small11: Botanical_Products11,
        hotProduct: 0,
        categoryWiseTable: 9,
        tableForm: 5,
        howToUse:
          "Dilute the recommended amount of emulsifier in the solution and mix thoroughly for uniform distribution.",
        productDetails:
          "Botanical products are derived from natural plant sources, including herbs, roots, leaves, flowers, and extracts. Botanical Agro Formulations provide effective and sustainable solutions for pest and disease management in agriculture. Our range of botanical products is designed to address various challenges faced by farmers, improving crop health and yield.",
        benefits: [
          {
            title: "Enhanced Stability and Performance",
            description: [
              "Ensures long-lasting emulsion stability under various environmental conditions.",
              "Prevents phase separation for consistent product application.",
              "Extends the shelf life of agrochemical formulations by up to 30%.",
            ],
          },
          {
            title: "Improved Coverage and Efficiency",
            description: [
              "Optimizes spray droplet distribution for better crop coverage.",
              "Minimizes chemical runoff, reducing environmental contamination.",
              "Enhances penetration in dense foliage and hard-to-reach areas.",
            ],
          },
          {
            title: "Eco-Friendly and Sustainable",
            description: [
              "Composed of biodegradable and non-toxic ingredients.",
              "Complies with global standards like EU REACH and FAO guidelines.",
              "Supports reduced agrochemical usage, fostering sustainable agriculture.",
            ],
          },
        ],
      },
      {
        subcategory: "Plant Growth Promoters",
        image: Plant_Growth_Promoters,
        big197: Plant_Growth_Promoters169,
        small11: Plant_Growth_Promoters11,
        hotProduct: 0,
        categoryWiseTable: 7,
        tableForm: 13,
        howToUse:
          "Dilute the recommended amount of emulsifier in the solution and mix thoroughly for uniform distribution.",
        productDetails:
          "Oil emulsifiers are essential components in agrochemical formulations, enabling the stable combination of oil and water phases. These emulsifiers prevent phase separation, ensure uniform application, and improve the efficiency of active ingredients. By enhancing the delivery of pesticides and fertilizers, oil emulsifiers contribute to better crop protection and growth, making them indispensable in modern agricultural practices.",
        benefits: [
          {
            title: "Enhanced Stability and Performance",
            description: [
              "Ensures long-lasting emulsion stability under various environmental conditions.",
              "Prevents phase separation for consistent product application.",
              "Extends the shelf life of agrochemical formulations by up to 30%.",
            ],
          },
          {
            title: "Improved Coverage and Efficiency",
            description: [
              "Optimizes spray droplet distribution for better crop coverage.",
              "Minimizes chemical runoff, reducing environmental contamination.",
              "Enhances penetration in dense foliage and hard-to-reach areas.",
            ],
          },
          {
            title: "Eco-Friendly and Sustainable",
            description: [
              "Composed of biodegradable and non-toxic ingredients.",
              "Complies with global standards like EU REACH and FAO guidelines.",
              "Supports reduced agrochemical usage, fostering sustainable agriculture.",
            ],
          },
        ],
      },
      {
        subcategory: "Essential Oil",
        image: Essential_Oil,
        big197: Essential_Oil169,
        small11: Essential_Oils11,
        hotProduct: 0,
        categoryWiseTable: 10,
        tableForm: 13,
        howToUse:
          "Dilute the recommended amount of emulsifier in the solution and mix thoroughly for uniform distribution.",
        productDetails:
          "Essential oils have gained significant attention in the agrochemical industry due to their natural origin, bioactive properties, and eco-friendly nature. Derived from plants, these volatile compounds offer a promising alternative to synthetic pesticides, fungicides, and insect repellents, contributing to sustainable agriculture.",
        benefits: [
          {
            title: "Enhanced Stability and Performance",
            description: [
              "Ensures long-lasting emulsion stability under various environmental conditions.",
              "Prevents phase separation for consistent product application.",
              "Extends the shelf life of agrochemical formulations by up to 30%.",
            ],
          },
          {
            title: "Improved Coverage and Efficiency",
            description: [
              "Optimizes spray droplet distribution for better crop coverage.",
              "Minimizes chemical runoff, reducing environmental contamination.",
              "Enhances penetration in dense foliage and hard-to-reach areas.",
            ],
          },
          {
            title: "Eco-Friendly and Sustainable",
            description: [
              "Composed of biodegradable and non-toxic ingredients.",
              "Complies with global standards like EU REACH and FAO guidelines.",
              "Supports reduced agrochemical usage, fostering sustainable agriculture.",
            ],
          },
        ],
      },
      {
        subcategory: "Pheromones",
        image: Pheromones,
        link: "/Pheromones",
      },
      {
        subcategory: "Others",
        image: Others,
        big197: Others169,
        small11: Other_Category11,
        hotProduct: 0,
        categoryWiseTable: 19,
        tableForm: 0,
        checkThisOnley: 1111,
        howToUse:
          "Dilute the recommended amount of emulsifier in the solution and mix thoroughly for uniform distribution.",
        productDetails:
          "Oil emulsifiers are essential components in agrochemical formulations, enabling the stable combination of oil and water phases. These emulsifiers prevent phase separation, ensure uniform application, and improve the efficiency of active ingredients. By enhancing the delivery of pesticides and fertilizers, oil emulsifiers contribute to better crop protection and growth, making them indispensable in modern agricultural practices.",
        benefits: [
          {
            title: "Enhanced Stability and Performance",
            description: [
              "Ensures long-lasting emulsion stability under various environmental conditions.",
              "Prevents phase separation for consistent product application.",
              "Extends the shelf life of agrochemical formulations by up to 30%.",
            ],
          },
          {
            title: "Improved Coverage and Efficiency",
            description: [
              "Optimizes spray droplet distribution for better crop coverage.",
              "Minimizes chemical runoff, reducing environmental contamination.",
              "Enhances penetration in dense foliage and hard-to-reach areas.",
            ],
          },
          {
            title: "Eco-Friendly and Sustainable",
            description: [
              "Composed of biodegradable and non-toxic ingredients.",
              "Complies with global standards like EU REACH and FAO guidelines.",
              "Supports reduced agrochemical usage, fostering sustainable agriculture.",
            ],
          },
        ],
      },
    ],
  },
  {
    category: "Fertilizers",
    image: fertilizer,
    big197: fertilizewe169,
    small11: fertilizewe11,
    hotProduct: 0,
    link: "/products",
    productDetails:
      "Fertilizers are essential components of modern agriculture, designed to replenish the soil with vital nutrients necessary for plant growth. They play a critical role in ensuring high crop yields, consistent quality, and sustainable farming practices. Fertilizers enhance soil fertility by supplying macronutrients such as nitrogen, phosphorus, and potassium, along with micronutrients essential for plant health. These products cater to diverse agricultural needs, from traditional farming to innovative methods like hydroponics and precision agriculture. With advances in technology, fertilizers are now formulated to be more efficient, eco-friendly, and tailored to specific crop requirements, helping farmers achieve better productivity while reducing environmental impact.",
    howToUse:
      "To ensure optimal results, fertilizers should be applied following these general guidelines: Identify the specific nutrient requirements of the soil and crop by conducting a soil test. Choose the appropriate fertilizer type based on the crop's growth stage and nutrient needs. Follow the recommended dosage and application method provided on the product label to avoid overuse or underuse. Apply the fertilizer uniformly across the field or targeted area using suitable tools or equipment, such as spreaders or sprayers. Ensure proper irrigation after application to help the fertilizer dissolve and reach the plant roots effectively. Always store fertilizers in a cool, dry place and handle them with care to prevent spillage or contamination.",
    benefits: [
      {
        title: "Versatile Applications",
        description: [
          "Suitable for agricultural formulations, such as pesticide emulsions and herbicides.",
          "Widely used in food, cosmetics, and industrial product formulations.",
          "Enhances stability in both oil-in-water and water-in-oil systems.",
        ],
      },
      {
        title: "Enhanced Performance",
        description: [
          "Improves shelf life by preventing phase separation over time.",
          "Increases product efficacy through uniform distribution.",
          "Reduces required dosages of active ingredients, optimizing costs.",
        ],
      },
      {
        title: "Eco-Friendly and Safe",
        description: [
          "Includes biodegradable options to minimize environmental impact.",
          "Complies with safety standards for use in sensitive applications, such as food and cosmetics.",
          "Free from harmful residues, ensuring user safety and environmental sustainability.",
        ],
      },
    ],
  },
  {
    category: "Silicone Super Spreader",
    image: silocon,
    // big197: fertilizewe169,
    small11: silocon,
    hotProduct: 3,
    link: "/products",
    howToUse:
      "Dilute the Silicone Super Spreader as directed and apply evenly on the target area for maximum effectiveness.",
    productDetails:
      "Silicone Super Spreader is a cutting-edge surfactant designed to enhance the spreading and absorption of agricultural sprays, including pesticides, herbicides, and fungicides. This innovative product reduces surface tension, allowing for uniform coverage on plant surfaces, even on waxy or difficult-to-wet leaves. Its superior spreading properties improve the penetration of active ingredients, ensuring higher efficacy and reduced wastage. Silicone Super Spreader is highly effective at low concentrations, making it a cost-efficient solution for modern farming needs. Suitable for use in various agricultural and horticultural applications, it supports sustainable and productive practices.",
    benefits: [
      {
        title: "Enhanced Coverage",
        description: [
          "Reduces surface tension to provide even spreading on all plant surfaces.",
          "Ensures effective application on hard-to-wet areas like waxy or hairy leaves.",
          "Improves penetration of active ingredients for better efficacy.",
          "Minimizes spray drift, ensuring targeted application.",
        ],
      },
      {
        title: "Cost Efficiency",
        description: [
          "Reduces the volume of spray solution required, saving resources.",
          "Highly effective at low concentrations, lowering overall product usage.",
          "Minimizes wastage by maximizing the efficiency of active ingredients.",
          "Offers long-term savings by improving yield and reducing reapplication needs.",
        ],
      },
      {
        title: "Eco-Friendly Solution",
        description: [
          "Decreases chemical runoff, reducing environmental impact.",
          "Supports sustainable farming by optimizing resource utilization.",
          "Safe for crops, soil, and surrounding ecosystems when used as directed.",
          "Complies with environmental and agricultural safety standards.",
        ],
      },
    ],
    videoLinks: [
      "https://youtu.be/GFtfutzCE-o?si=v9KfLGZKm5IP1v4R",
      "https://youtu.be/tMEJ60ZTOGA?si=iGZ962xdf5YgXJgU",
      "https://youtu.be/mpW5EGLfs7w?si=VzoOTbMBHMkiXCLP",
    ],
  },
  {
    category: "Paint Additives",
    image: paint,
    big197: Paint_Additives169,
    small11: Paint_Coatings11,
    hotProduct: 0,
    link: "/products",
    howToUse:
      "Mix the recommended quantity of the additive into the paint thoroughly before application.",
    productDetails:
      "Paint additives are essential for enhancing the functionality and performance of paints. They improve properties such as durability, adhesion, drying time, and resistance to environmental factors. These additives are used in various industries, including automotive, construction, and industrial coatings, offering solutions tailored to specific challenges. By incorporating paint additives, users can achieve superior results, ensuring longer-lasting and more effective coatings.",
    benefits: [
      {
        title: "Superior Performance",
        description: [
          "Enhances adhesion, ensuring long-lasting coatings on diverse surfaces.",
          "Reduces drying time, allowing faster application cycles.",
          "Improves resistance to UV rays, chemicals, and abrasion for extended durability.",
        ],
      },
      {
        title: "Application Versatility",
        description: [
          "Compatible with water-based and solvent-based paint systems.",
          "Suitable for application on metal, wood, concrete, and plastic surfaces.",
          "Ensures uniform pigmentation and prevents sedimentation during storage.",
        ],
      },
      {
        title: "Eco-Friendly and Cost-Effective",
        description: [
          "Formulated with environmentally friendly ingredients that comply with regulations.",
          "Reduces wastage by optimizing the efficiency of paint applications.",
          "Highly concentrated for maximum performance with minimal dosage.",
        ],
      },
    ],
  },
  {
    category: "General Emulsifiers",
    image: general,
    big197: General_Emulsifiers,
    small11: General_Manufacturing11,
    hotProduct: 0,
    link: "/products",
    MostProduct: 4,
    howToUse:
      "Dilute the recommended quantity into the desired formulation and mix thoroughly to ensure uniform dispersion.",
    productDetails:
      "General emulsifiers are multifunctional agents used to stabilize mixtures of immiscible liquids, such as oil and water. They enhance the homogeneity, stability, and application performance of formulations across diverse industries. Widely utilized in paints, coatings, personal care, and agriculture, emulsifiers help achieve consistent quality and improved efficiency. These additives are critical for ensuring long-lasting stability, especially in formulations exposed to varying environmental conditions.",
    benefits: [
      {
        title: "Enhanced Stability",
        description: [
          "Prevents phase separation, ensuring uniform consistency in emulsions.",
          "Improves the shelf life of formulations by up to 50%.",
          "Reduces sedimentation and enhances texture for better product appeal.",
        ],
      },
      {
        title: "Wide Industry Applications",
        description: [
          "Ideal for use in paints, coatings, cosmetics, and food formulations.",
          "Supports both oil-in-water and water-in-oil systems, enhancing versatility.",
          "Compatible with advanced manufacturing processes, optimizing scalability.",
        ],
      },
      {
        title: "Eco-Friendly Solutions",
        description: [
          "Includes biodegradable and bio-based options for sustainable applications.",
          "Reduces energy and resource consumption during production.",
          "Complies with global safety standards, ensuring environmentally conscious use.",
        ],
      },
    ],
  },
  {
    category: "Home & Industrial Cleaning",
    image: indrutial,
    big197: Manufacturing_Products169,
    small11: indrutial,
    link: "/Agro",
    productDetails:
      "Fenton Chemicals delivers innovative cleaning solutions backed by research in surfactant chemistry and soil removal. Our range includes biodegradable detergents, heavy-duty degreasers, and disinfectants designed to tackle diverse cleaning challenges. Whether for household sanitation or industrial-grade cleaning, these solutions combine high performance with environmental safety. Formulated for low toxicity and eco-friendliness, they cater to industries such as healthcare, food processing, and manufacturing, ensuring compliance with global standards.",
    subcategories: [
      {
        subcategory: "Manufacturing Products",
        image: Manufacturing_Products,
        big197: Manufacturing_Products169,
        small11: Manufacturing_Products11,
        hotProduct: 6,
        tableForm: 0,
        howToUse:
          "Use the recommended quantity directly on the surface or dilute as per the product instructions for optimal results.",
        productDetails:
          "Our manufacturing products encompass a diverse range of cleaners, including degreasers, disinfectants, and specialty solutions. These products are formulated for specific tasks such as equipment cleaning, food-grade sanitation, and surface care. Designed with eco-friendly surfactants and advanced formulations, they deliver superior cleaning performance while ensuring user safety and minimal environmental impact.",
        benefits: [
          {
            title: "High-Performance Cleaning",
            description: [
              "Effectively removes tough grease, grime, and microbial contaminants.",
              "Suitable for a variety of applications, including kitchens, factories, and machinery.",
              "Reduces cleaning time by up to 30% with concentrated solutions.",
            ],
          },
          {
            title: "Eco-Friendly Formulations",
            description: [
              "Biodegradable surfactants minimize environmental impact.",
              "Free from phosphates and harmful chemicals for user safety.",
              "Promotes water conservation with reduced rinse requirements.",
            ],
          },
          {
            title: "Wide Applicability",
            description: [
              "Suitable for diverse surfaces, including glass, metal, and wood.",
              "Offers specialized solutions for food-grade and industrial cleaning needs.",
              "Includes anti-corrosive properties to protect equipment.",
            ],
          },
        ],
        videoLinks: [
          "https://youtu.be/5VpWnmYv4fs?si=1_KH7OIHHTFnlDa6",
          "https://youtu.be/gHMlrZ9efTQ?si=pwRB___mHddABibF",
        ],
      },
      {
        subcategory: "Trading Products",
        image: Trading_Products,
        big197: Trading_Products169,
        small11: Trading_Products11,
        hotProduct: 6,
        tableForm: 0,
        howToUse:
          "Follow the product label for precise application methods, including dilution and usage instructions.",
        productDetails:
          "Our trading products include a curated selection of cleaning agents and industrial solutions sourced from trusted global partners. These products are chosen for their quality, reliability, and compliance with safety standards. With a focus on meeting client-specific needs, our trading portfolio spans agriculture, textiles, paints, and cleaning solutions, ensuring consistent supply and optimal performance.",
        benefits: [
          {
            title: "Diverse Product Range",
            description: [
              "Offers detergents, disinfectants, and specialty cleaning formulations.",
              "Flexible packaging options for small-scale or bulk requirements.",
              "Customizable products tailored to specific client needs.",
            ],
          },
          {
            title: "Quality and Compliance",
            description: [
              "Sourced from certified suppliers meeting international safety standards.",
              "Undergoes rigorous quality assurance checks to ensure performance.",
              "Comes with full regulatory compliance documentation for traceability.",
            ],
          },
          {
            title: "Reliable Supply Chain",
            description: [
              "Ensures timely delivery with robust logistics networks.",
              "Handles varying demand through scalable inventory management.",
              "Minimizes supply disruptions with consistent stock availability.",
            ],
          },
        ],
      },
    ],
  },
];

export const stats = [
  {
    title: "Satisfied Customers",
    count: 2000,
  },
  {
    title: "Premium Products",
    count: 300,
  },
  {
    title: "Years of Experience",
    count: 37,
  },
];

export const aboutPoints = [
  {
    id: 0,
    title: "Innovative Chemical Solutions",
    icon: about1,
  },
  {
    id: 1,
    title: "Commitment to Sustainability",
    icon: about2,
  },
  {
    id: 2,
    title: "Global Distribution Network",
    icon: about3,
  },
  {
    id: 3,
    title: "State-of-the-Art Research Facilities",
    icon: about4,
  },
  {
    id: 4,
    title: "Exceptional Quality Standards",
    icon: about5,
  },
  {
    id: 5,
    title: "Dedicated Customer Support",
    icon: about1,
  },
];

export const singleProductDescriptionTabs = [
  {
    id: 1,
    title: "Description",
  },
  {
    id: 2,
    title: "General & Features",
  },
  {
    id: 3,
    title: "Instructions",
  },
  {
    id: 4,
    title: "Video Guides",
  },
];

export const foundersList = [
  {
    name: "Dilip Jain",
    role: "Founder",
    image: delip,
    description:
      "Dilip Jain. In 1988, Dilip Jain, a visionary entrepreneur with an MSc in organic chemistry from the University of Pune, established DK Corporation. He initially engaged in chemical trading before transitioning to the manufacturing of specialty chemicals, including emulsifiers. Mr. Jain was driven by his recognition of raw material shortages in Central India and his aspiration to improve the region's chemical industry. As a result, DK Corporation gained a reputation for cost-effective solutions and trust throughout India.",
  },
  {
    name: "Arpit Jain",
    role: "Founder",
    image: arpit,
    description:
      "Arpit Jain, a chemical engineering graduate from IIT BHU and the son of Dilip Jain, founded Fenton Chemicals in Indore in 2014. He expanded the company's production capacity tenfold and concentrated on agro-emulsifiers and the agro-industry. Mr. Jain leveraged a strong distribution network and a team of technical researchers to provide innovative, high-quality solutions that continued and expanded his father's legacy.",
  },
  {
    name: "Akshay Jain",
    role: "Founder",
    image: akshay,
    description:
      "Akshay Jain, the youngest son of Mr. Dilip Jain, established Fenrich Silicones and Surfactants in 2019, a specialized manufacturing unit dedicated exclusively to silicone-based products. With a focus on innovation and high-performance formulations, Fenrich Silicones and surfactants caters to diverse industries, including agrochemicals, coatings, textiles, and personal care. By leveraging advanced technology and a deep understanding of silicones and surfactants, Akshay Jain has positioned Fenrich as a trusted supplier of premium silicone solutions, complementing the legacy of DK Corporation and Fenton Chemicals while addressing the growing market demand for high-quality specialty Silicone chemicals.",
  },
];
